<template>
  <div>
    <b-row>
      <b-col cols="2"/>
      <b-col cols="8" style="font-size: 14px">
        <SubHeader class="mb-2">
          <b-row class="mt-2 mb-1">
            <b-col cols="3">
              <b-form-input v-model="search" debounce="500" size="sm" placeholder="Suche nach Portalen..."/>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox v-model="inactive" inline>
                <b-badge id="tt-inactive" class="mr-2" variant="primary">Inaktiv</b-badge>
                <b-tooltip target="tt-inactive" triggers="hover">Anzeige aller inaktiven Einträge</b-tooltip>
              </b-form-checkbox>
            </b-col>
            <b-col cols="auto" class="ml-auto">
              <b-button variant="primary" size="sm" @click="add">Neues Portal</b-button>
            </b-col>
          </b-row>
        </SubHeader>
        <div v-if="portalsFiltered">
          <div v-for="portal in portalsFiltered" :key="portal.id" class="mt-3">
            <PortalRow :portal="portal" @edit-portal="edit"/>
          </div>
        </div>
        <b-row v-else cols="1">
          <b-col>
            <h5 class="text-secondary text-center mt-5">Keine Daten vorhanden</h5>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="2"/>
    </b-row>
    <PortalModal :method="method" :data="data" @refresh="refresh"/>
    <ScrollToTop/>
  </div>
</template>

<script>
import _ from "lodash";
import {mapActions} from "vuex";
import {getRequest} from "@/modules/requests";
import SubHeader from "@/components/structure/SubHeader";
import ScrollToTop from "@/components/utility/ScrollToTop";
import PortalRow from "@/components/fg-software/webPortals/PortalRow.vue";
import PortalModal from "@/components/fg-software/webPortals/PortalModal.vue";

export default {
  name: "WebPortalManage",
  components: {
    SubHeader,
    PortalRow,
    PortalModal,
    ScrollToTop
  },
  data() {
    return {
      search: '',
      data: null,
      method: null,
      portals: null,
      inactive: false
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    add() {
      this.method = 'add'
      this.$bvModal.show('portalModal')
    },
    edit(data) {
      this.data = data;
      this.method = 'edit';
      this.$bvModal.show('portalModal')
    },
    async refresh() {
      this.setLoadingState(true)
      await this.getPortalsData()
      this.setLoadingState(false)
    },
    async getPortalsData() {
      this.portals = []
      await getRequest('/portals/fg/all', null, this)
          .then((response) => {
            this.portals = response.data
          })
    }
  },
  computed: {
    portalsFiltered() {
     return _.filter(this.portals, (entry) => {
       if(!this.inactive) {
         return entry.displayname.toLowerCase().includes(this.search.toLowerCase()) && entry.inactive === false
       }
       else {
         return entry.displayname.toLowerCase().includes(this.search.toLowerCase())
       }
     })
    }
  },
  created() {
    this.refresh()
  }
}
</script>

<style lang="scss" scoped>
</style>
