<template>
  <Tile :title="title">
    <b-card body-class="p-2 py-3" class="p-0">
      <b-card-body class="tile-expand-content p-0 py-3">
        <slot/>
      </b-card-body>
    </b-card>
  </Tile>
</template>

<script>
import Tile from "@/components/details/Tile";

export default {
  name: "PortalTile",
  props: ['title'],
  components: {
    Tile
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/details/Tiles.scss';
</style>
